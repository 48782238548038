import { useWindowWidth } from '@react-hook/window-size'
import React, { useState } from 'react'
import { Box, Button, Flex, Text, Image } from 'rebass'
import { trackHandler, useSupportedBrowser } from 'uneeq-react-core'
import { UnsupportedBrowser } from 'uneeq-react-ui'
import config from '../../config'
import styles from './styles'
import atlantisLogo from '../assets/img/ah-logo-horzontal-global.svg'

const Paragraphs = () => (
  <>
    <Text as="p">
      We take your privacy seriously, we only use video and audio data so that
      {' ' + config?.avatarName} can understand what you are saying to it. We
      then share audio data with third party service partners who help us with
      transcription. Neither they or we store any audio data once we have
      completed the transcription. For more information read our{' '}
      <Text
        as="a"
        href="https://atlantishealth.com/us/privacy-notice/"
        target="_blank"
        rel="noreferrer noopener"
        sx={styles.privacyPolicyLink}
      >
        privacy policy
      </Text>
      .
    </Text>
  </>
)

const SophieVideo = () => {
  const width = useWindowWidth()
  const smallScreen = width < 1024

  return (
    <video
      style={{ objectPosition: smallScreen ? '70%' : undefined }}
      playsInline
      autoPlay
      muted
      loop
      poster={'/images/idle.jpg'}
    >
      <source src={'/videos/idle.webm'} type="video/webm" />
    </video>
  )
}

interface HomeProps {
  startSession: (token: string) => void
}

const Home: React.FC<HomeProps> = ({ startSession }) => {
  const { isBrowserSupported } = useSupportedBrowser()
  const [showPasscode, setShowPasscode] = useState(false)

  if (showPasscode && !isBrowserSupported) {
    return <UnsupportedBrowser close={() => setShowPasscode(false)} />
  }

  const StartButton = ({ sx }: any) => (
    <Button
      variant="outline"
      onClick={() => {
        trackHandler(startSession, 'lets-chat-btn')(true)
      }}
      sx={{
        ...styles.letsChatButton,
        ...sx,
        backgroundColor: '#e72177',
        borderColor: '#e72177'
      }}
    >
      LET'S CHAT
    </Button>
  )

  return (
    <Flex sx={styles.mainContainer}>
      <Text sx={styles.mobileImSophie}>
        Meet {config?.avatarName}, your Digital Behaviour Change Coach.
      </Text>
      <SophieVideo />

      <Box sx={styles.textContainer}>
        <Image src={atlantisLogo} sx={styles.logoContainer}></Image>
        <Box sx={styles.titleContainer}>
          <Text sx={styles.imSophieText}>Meet {config?.avatarName}</Text>
        </Box>

        <Text sx={styles.subtitle}>
          I'm a digital behavior health coach designed to interact with your
          customers.
        </Text>
        <StartButton sx={styles.startButton} />

        <Flex
          sx={{
            ...styles.paragraphContainer
          }}
        >
          <Paragraphs />
        </Flex>
        <StartButton
          sx={{ display: ['none', 'none', 'none', 'none', 'block', 'block'] }}
        />
      </Box>
    </Flex>
  )
}

export default Home

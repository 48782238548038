const query = new URLSearchParams(window.location.search)
export const assistantId: string | null = query.get('assistantId')
export const code: string | null = query.get('code')
export const grant_type: string | null = query.get('grant_type')
export const scope: string | null = query.get('scope')

export const getRedirectURI = () => {
    const url = window.location.href
    return url.replace(/&code=.*$/, '')
}
